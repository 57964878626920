<template>
  <div class="height1">
    <!--      头部      -->
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="Search">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="姓名"
                  @keyup.enter.native="Search" clearable ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="tableData.length>0" :data="tableData" :fields="json_fields"
                  worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                  <el-button type="warning" size="mini" class="el-icon-download">导出当前数据</el-button>
                </download-excel>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="DataTotal" :fields="json_fields" :fetch="fetchData" worksheet="My Worksheet"
                  :header="title" :name="`${title}_All.xls`" class="excel">
                  <el-button type="danger" size="mini" class="el-icon-download">导出全部数据</el-button>
                </download-excel>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 90px)" :header-cell-style="{background:'#F5F7FC'}">
          <el-table-column prop="patient.name" label="姓名"> </el-table-column>
          <el-table-column prop="patient.age" label="年龄" align="center">
          </el-table-column>
          <el-table-column prop="patient.phone" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="patient.idNo" label="身份证号" align="center" width="170px">
          </el-table-column>
          <el-table-column prop="prescriptionName" label="方剂名称" align="center">
          </el-table-column>
          <el-table-column prop="dosage" label="方剂数量" align="center">
            <template slot-scope="scope">
              <el-tag type="warning" size="small">{{
              scope.row.dosage
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="addTime" label="开方时间" align="center" width="150px">
            <template slot-scope="scope">
              {{ scope.row.addTime | timefilters }}
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="开方人" align="center">
          </el-table-column>
          <!-- <el-table-column prop="organization" label="审核医生">
                        <template slot-scope="scope">
                            <span v-if="scope.row.auditStatus == 1">{{scope.row.organization}}</span>
                            <span v-else>未审核</span>
                        </template>
                    </el-table-column> -->
          <el-table-column prop="auditTime" label="审核时间" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.auditStatus == 1">{{
              scope.row.auditTime | timefilters
              }}</span>
              <span v-else>未审核</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.auditStatus == 0" class="warning optionBtn" @click="audit(scope.row)">审核
              </span>
              <span v-if="scope.row.auditStatus == 1" class="success optionBtn" 
                @click="handelFinish(scope.row)">完成备药</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal"
          layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Prescription } from "../../../components/HospitalDomain/Patient/Prescription";
import { Patient } from "../../../components/HospitalDomain/Patient/Patient";
import { CodeNode } from "source-list-map";

export default {
  name: "Prescription",
  components: {
    bread,
  },
  data() {
    var patient = new Patient(this.TokenClient, this.Services.Authorization);
    var prescription = new Prescription(this.TokenClient, this.Services.Drug);
    return {
      patientDomain: patient,
      PrescriptionDomain: prescription,
      organizationId: JSON.parse(sessionStorage.getItem("userInfo"))
        .organizationId,
      userId: JSON.parse(sessionStorage.getItem("userInfo")).id,
      tableData: [],
      searchForm: {
        statrTime: "",
        endTime: "",
        keyWord: "",
        datepicker: "",
      },
      auditStatus: 1,
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      patientList: [],
      userList: JSON.parse(sessionStorage.getItem("UserSession")),
      title: '处方审查列表',
      DataCount: 0,
      json_fields: {
        "姓名": {
          callback: val => {
            return val.patient.name
          }
        },
        "年龄": {
          callback: (val) => {
            return val.patient.age
          }
        },
        "手机号": {
          callback: (val) => {
            return val.patient.phone
          }
        },
        "身份证号": {
          callback: val => {
            return `CID：${val.patient.idNo}`
          }
        },
        "方剂名称": 'prescriptionName',
        "方剂数量": 'dosage',
        "开方时间": {
          filed: 'addTime',
          callback: (val) => {
            var val = val.addTime
            return this.$fliters.timefilters(val)
          }
        },
        "开方人": 'userName',
        "审核时间": {
          filed: 'auditTime',
          callback: (val) => {
            var val = val.auditTime
            return val == '0001-01-01T00:00:00' ? '未审核' : this.$fliters.timefilters(val)
          }
        },
      },
    };
  },
  mounted() {
    this.GetList();
    // 查询所有患者信息
    // this.getPatients()
  },
  methods: {
    getPatients() {
      this.patientDomain.Patients(
        (data) => {
          this.patientList = data.data;
          this.GetList();
        },
        (err) => {
          console.log(err);
        }
      );
    },
    SearchInputKeydown() {
      this.PageIndex = 1;
      this.GetList();
    },
    Search() {
      this.PageIndex = 1;
      this.GetList();
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex;
      this.GetList();
    },
    async fetchData() {
      var _this = this;
      return await _this.getAllData()
    },
    getAllData() {
      var _this = this;
      return new Promise((resolve, reject) => {
        var item = _this.searchForm;
        if (_this.searchForm.datepicker) {
          item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
          item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
        } else {
          item.startTime = "";
          item.endTime = "";
        }
        _this.PrescriptionDomain.AuditPrescriptionList(
          _this.organizationId,
          item.startTime,
          item.endTime,
          item.keyWord,
          1, _this.DataCount,
          function (data) {
            resolve(data.data.results);
          },
          function (err) {
            resolve('');
            console.log(err);
          });
      })

    },
    GetList() {
      var _this = this;
      _this.tableData = [];
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }
      _this.PrescriptionDomain.AuditPrescriptionList(
        _this.organizationId,
        item.startTime,
        item.endTime,
        item.keyWord,
        _this.PageIndex, null,
        function (data) {
          _this.tableData = data.data.results;
          _this.PageIndex = data.data.pageIndex;
          _this.PageSize = data.data.pageSize;
          _this.PageTotal = data.data.pageCount;
          _this.DataTotal = data.data.dataTotal;
          if (!item.keyWord) {
            _this.DataCount = data.data.dataTotal;
          }
        },
        function (error) {
          console.log(error);
        }
      );
    },
    handelFinish(item) {
      var _this = this;
      this.$confirm("确定完成备药吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.PrescriptionDomain.FinishPrescription(
            item.id,
            "1",
            function (data) {
              _this.$message({
                type: "success",
                message: "完成备药!",
              });
              _this.GetList();
            },
            function (error) {
              console.log(error);
            }
          );
        })
        .catch(() => { });
    },
    audit(item) {
      var _this = this;
      _this.$router.push({
        path: "/Patient/AuditingPrescription",
        query: {
          patientPrescriptionId: item.id,
          patientId: item.patientId,
          patientDiagnoseId: item.patientDiagnoseId,
        },
      });
    },
  },
};
</script>

<style scoped>
/*content*/


.table-top {
  /* margin-bottom: 15px; */
}
</style>
